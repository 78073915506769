import styled from '@emotion/styled'
import { FlexBox } from 'app/components/layout/FlexBox'
import React, { memo } from 'react'

const NotFoundPage = memo(function NotFoundPage() {
  return (
    <Container dial={5}>
      <h1>Not found</h1>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 100vh;
`

export default NotFoundPage
